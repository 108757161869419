import Swal from "sweetalert2";

const ConfirmDialog = Swal.mixin({
    showCancelButton: true,
    showCloseButton: false,
    focusConfirm: false,
    icon: "info",
    iconColor: '#0d9488',
    confirmButtonColor: '#0d9488',
    reverseButtons: true,
    cancelButtonColor: '#18181b',
    cancelButtonText: "Cancel",
    customClass: {
        cancelButton: "hover:!g-gray-800 focus:ring-teal-500",
        confirmButton: "hover:bg-teal-700 focus:ring-teal-500",
        container: 'swal2-container'
    },
});


const Toast = Swal.mixin({
    toast: true,
    position: 'top-right',
    iconColor: 'white',
    customClass: {
        popup: 'colored-toast',
        container: 'swal2-container'
    },
    showConfirmButton: false,
    timer: 1800,
    timerProgressBar: true,
})


export { ConfirmDialog, Toast }
