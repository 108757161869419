
const JSONGetRequest = ({ href, onResult, onLoading = (val) => { }, onError = (msg) => { } }) => {
    onLoading(true);
    window.axios.get(href)
        .then(({ data }) => {
            onResult(data);
        })
        .catch(error => {
            if (typeof onError === 'function')
                onError(error)
        })
        .finally(() => onLoading(false));
}

const JSONPostRequest = ({ href, data, onResult, onLoading = (val) => { }, onError = (msg) => { } }) => {
    onLoading(true);
    window.axios.post(href, data)
        .then(({ data }) => {
            onResult(data);
        })
        .catch(error => {
            if (typeof onError === 'function')
                onError(error)
        })
        .finally(() => onLoading(false));
}

const JSONPutRequest = ({ href, data, onResult, onLoading = (val) => { }, onError = (msg) => { } }) => {
    onLoading(true);
    window.axios.put(href, data)
        .then(({ data }) => {
            onResult(data);
        })
        .catch(error => {
            if (typeof onError === 'function')
                onError(error)
        })
        .finally(() => onLoading(false));
}

const JSONDeleteRequest = ({ href, onResult, onLoading = (val) => { }, onError = (msg) => { } }) => {
    onLoading(true);
    window.axios.delete(href)
        .then(({ data }) => {
            onResult(data);
        })
        .catch(error => {
            if (typeof onError === 'function')
                onError(error)
        })
        .finally(() => onLoading(false));
}

const JSONPostFileRequest = ({ href, data, onResult = () => { }, onLoading = (val) => { }, onError = (msg) => { } }) => {
    onLoading(true);
    window.axios.post(href, data, {
        headers: {
            'Content-Type': 'multipart/form-data'
        },
    }).then(({ data }) => {
        onResult(data);
    })
        .catch(error => {
            if (typeof onError === 'function')
                onError(error)
        })
        .finally(() => onLoading(false));
}
export { JSONGetRequest, JSONPostRequest, JSONPutRequest, JSONDeleteRequest, JSONPostFileRequest }
