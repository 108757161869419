import "@css/app.css";
import "@splidejs/react-splide/css";
import "aos/dist/aos.css";
import "driver.js/dist/driver.css";
import 'react-multi-carousel/lib/styles.css';
import 'react-photo-view/dist/react-photo-view.css';
import "./bootstrap";

import { createInertiaApp, router } from "@inertiajs/react";
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import { resolvePageComponent } from "laravel-vite-plugin/inertia-helpers";
import mitt from 'mitt';
import { StrictMode } from 'react';
import { createRoot } from "react-dom/client";
import { setLoader } from "./Hooks/Functions";

window.emitter = mitt();

const queryClient = new QueryClient()
const appName = import.meta.env.VITE_APP_NAME || "Rock My Prompt";

router.on('start', () => setLoader(true, 'Navigating...'));
router.on('finish', () => setLoader(false));

createInertiaApp({
    title: (title) => !!title ? `${title} - ${appName}` : appName,
    resolve: (name) => resolvePageComponent(`./Pages/${name}.jsx`, import.meta.glob("./Pages/**/*.jsx")),
    setup({ el, App, props }) {
        const root = createRoot(el);
        root.render(<StrictMode>
            <QueryClientProvider client={queryClient}>
                <App {...props} />
            </QueryClientProvider>
        </StrictMode>);
    },
    progress: {
        color: "#4B5563",
        showSpinner: true,
    },
});
